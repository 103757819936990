import { useContext } from 'react';
import { SiteText } from '../components/site_text';
import styled from 'styled-components';
import { SupportContextData } from '../components/support_context';
import useWindowSize from '../components/hooks/useWindowSize';
import { PageImageContainer, PageImage } from '../components/containers';


const OutsideDiv = styled.div`
    overflow-y: scroll;
    max-height: 80%;
    margin-top: 20px;
`

const NoteBackground = styled.div`
    background: url("${props => props.url}");
    background-size: cover;
    padding: 200px 150px 30px 50px;
`

const InfoHolderDiv = styled.div`
    margin-top: -5vh;
`

const FlyerTitle = styled.div`
    font-size: 1.6em;
    font-weight: 500;
`

const ListText = styled.div`
    font-size: 1.2em;
    padding-left: 20px;
`

const ListLink = styled.a`
    font-size: 1.2em;
    padding-left: 20px;
    text-decoration: underline;
    color: black;
    text-align: left;
`

export default function Itinerary() {

    const { width, height } = useWindowSize();
    const narrow = width < 1440;
    const short = height < 800;

    const supportContext = useContext(SupportContextData)

    if(!supportContext) {
        return(
            <></>
        )
    } else {
        return (
            <PageImageContainer narrow={narrow} short={short}>
                <PageImage src={supportContext.details}/>
                {/*
                <NoteBackground url={supportContext.note_edited}>
                    <InfoHolderDiv>
                    <FlyerTitle>Accomodations</FlyerTitle>
                    <div style={{marginTop: "2vh", fontSize: "1.2em"}}>Bonomo - Baroody Wedding special group rate at:</div>
                    <br/>
                    <div style={{display: "flex", alignItems: "start", justifyContent: "space-around"}}>
                        <div>
                            <img src={supportContext.fairfield_inn} />
                        </div>
                        <div style={{marginTop: ".8em"}}>
                            <div style={{textAlign: "center", fontSize: "1.4em"}}>Fairfield Inn & Suites</div>
                            <div style={{marginTop: ".2em", textAlign: "center", fontSize: "1.1em"}}>5280 Willow Pl, Verona, NY 13478</div>
                            <div style={{marginTop: ".6em", textAlign: "center", fontSize: "1em"}}>$169 USD - $174 USD plus tax per night</div>
                            <div style={{marginTop: ".8em", textAlign: "center", fontSize: "1.2em"}}>
                                <a href='https://www.marriott.com/event-reservations/reservation-link.mi?id=1692122800230&key=GRP&app=resvlink' target="_blank">Book your group rate for Bonomo Baroody wedding</a>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "1.2em", textAlign: "center", fontSize: "1.1em"}}>Located 14 mins from Hayloft on the Arch wedding venue, across the street from the Turning Stone Casino and Resort</div>
                    <div style={{marginTop: ".8em", textAlign: "center", fontSize: "1.1em", fontWeight: 500}}>There will be a complimentary shuttle service from this hotel, to the venue, and back</div>
                    <br/>
                    <div style={{marginTop: "1.4em", textAlign: "center", fontSize: "1.2em"}}>Rooms can be booked for 1, 2 or 3 nights</div>
                    <div style={{marginTop: ".8em", textAlign: "center", fontSize: "1.1em"}}>The hotel includes complimentary shuttles that run to and from the casino</div>
                    <div style={{marginTop: "1.8em", display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: 10}}>
                            <div style={{fontSize: "1.6em", fontWeight: 500}}>Start Date</div>
                            <div style={{marginTop: ".4em", textAlign: "center", fontSize: "1.2em"}}>Friday, August 30, 2024</div>
                        </div>
                        <div style={{borderLeft: "2px solid black", height: "6vh"}}></div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: 10}}>
                            <div style={{fontSize: "1.6em", fontWeight: 500}}>End Date</div>
                            <div style={{marginTop: ".4em", textAlign: "center", fontSize: "1.2em"}}>Monday, September 2, 2024</div>
                        </div>
                        <div style={{borderLeft: "2px solid black", height: "6vh"}}></div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: 10}}>
                            <div style={{textAlign: "center", fontSize: "1.6em", fontWeight: 500}}>Last Day to Book</div>
                            <div style={{marginTop: ".4em", textAlign: "center", fontSize: "1.2em"}}>Tuesday, July 30, 2024</div>
                        </div>
                    </div>
                    <br/>
                    <hr style={{width: "80%"}}/>
                    <div style={{marginTop: "2vh", fontSize: "1.4em", paddingLeft: "3vw"}}>Other Hotel Options near Hayloft on the Arch:</div>
                    <br/>
                    <div style={{display: "flex", justifyContent: "start", marginLeft: "3vw"}}>
                        <ul style={{marginTop: "-10px"}}>
                            <li style={{textAlign: "left"}}><a href='https://www.turningstone.com/accommodations' target="_blank"><ListText>Turning Stone Resort and Casino (Verona, NY)</ListText></a></li>
                            <li style={{textAlign: "left"}}><a href='https://www.hilton.com/en/hotels/syrvehx-hampton-verona-at-turning-stone/' target="_blank"><ListText>Hampton Inn Verona at Turning Stone (Verona, NY)</ListText></a></li>
                            <li style={{textAlign: "left"}}><a href='https://vernondowns.com/hotel/' target="_blank"><ListText>Vernon Downs Casino & Hotel (Vernon, NY)</ListText></a></li>
                        </ul>
                    </div>
                    </InfoHolderDiv>
                </NoteBackground>
                <NoteBackground url={supportContext.note_edited}>
                    <InfoHolderDiv>
                        <FlyerTitle>
                            Things To Do
                        </FlyerTitle>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{flex: 2, display: "flex", justifyContent: "start"}}>
                            <ul>
                                <li style={{textAlign: "left"}}><ListLink href='https://www.nickydoodles.com/' target="_blank">Nicky Doodle's - Ice Cream - 1.4 Miles from Fairfield Inn</ListLink></li>
                                <li style={{marginTop: "10px", textAlign: "left"}}><ListLink href='https://nysfair.ny.gov/' target="_blank">The Great NY State Fair - 40 mins from Verona, NY</ListLink></li>
                                <li style={{marginTop: "10px", textAlign: "left"}}><ListLink href='https://www.turningstone.com' target="_blank">Turning Stone Resort & Casino - Golf, Spa, Nightlife, Dining</ListLink></li>
                                <li style={{marginTop: "10px", textAlign: "left"}}><ListLink href='https://www.recoverysportsgrill.com/verona/' target="_blank">Recovery Sports Grille Vernon, NY - Located next to Fairfield Inn & Suites</ListLink></li>
                            </ul>
                        </div>
                        {/*<div style={{flex: 1, height: '46vh'}}>
                            <img src={supportContext.state_fair} width="300" style={{transform: "rotate(90deg)"}}/>
                        </div>*
                        </div>
                    </InfoHolderDiv>
                </NoteBackground>*/}
            </PageImageContainer>
        )
    }
}