import styled from 'styled-components';
import useWindowSize from '../components/hooks/useWindowSize';


const DFC = styled.div`
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
`
const QAContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    background-color: white;
    border-radius: 20px;
    border: 1px solid black;
    padding: 12px;
    max-height: ${props => (props.narrow ?  "" : (props.nottooshort ? "58vh" : (props.short ? "30vh" : "68vh")))};
    overflow-y: auto;
`

const StyledLabel = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 2.2em;
    font-weight: 500;
    text-decoration-thickness: 2px;
    color: black;
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 2px;
`

const StyledText = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 2em;
    font-weight: 400;
    color: black;
    text-align: center;
`

const OptionText = styled.div`
    font-size: 14px;
    font-weight: 500;
`

const OptionLabel = styled.div`
    font-size: 18px;
    font-weight: 700;
`



export default function MobileQA() {

    const { width, height } = useWindowSize();
    const narrow = width < 1280;
    const short = height < 700;
    const nottooshort = (height >= 700 && height < 950);

    
    return (
        <DFC>
            <QAContainer narrow={narrow} short={short} nottooshort={nottooshort}>
                <div style={{textAlign: "center"}}>
                    <StyledLabel>Q & A</StyledLabel>
                </div>
                <br />
                <p style={{textAlign: "center"}}>
                    <p><OptionLabel>I have a food allergy, can I make a special request?</OptionLabel></p>
                    <OptionText>Yes, we are happy to accommodate your needs! Please include dietary requirements on your RSVP card or on our RSVP page.</OptionText>
                </p>
                <p style={{textAlign: "center"}}>
                    <p><OptionLabel>When should I RSVP?</OptionLabel></p>
                    <OptionText>We kindly ask for an RSVP by July 22nd</OptionText>
                </p>
                <p style={{textAlign: "center"}}>
                    <p><OptionLabel>When should I book my hotel?</OptionLabel></p>
                    <OptionText>If you plan to use our hotel block, please book by July 30th (or earlier). See the Travel Info section on our website - that will take you to the hotel page. If the website is not working, please refer to Maggie or Mike and we will add more rooms. You may also call the hotel to book! Be sure to mention you are booking for the Bonomo-Baroody wedding to receive the discounted rate.</OptionText>
                </p>
                <p style={{textAlign: "center"}}>
                    <p><OptionLabel>Will there be a shuttle?</OptionLabel></p>
                    <OptionText>Yes. Transportation will be provided from the Fairfield Inn, to the venue and back. Departure times to the venue are 2:30 and 3:10 p.m.. Please be in the Fairfield Inn hotel lobby at least 10 minutes before departure time. Shuttle capacity is limited - if you are able to take the early shuttle please do so and enjoy the venue grounds when you arrive!</OptionText>
                </p>
                <p style={{textAlign: "center"}}>
                    <p><OptionLabel>Can I leave my car at the venue overnight?</OptionLabel></p>
                    <OptionText>Yes! There is a large parking lot at The Hayloft on the Arch. If you would like to take the shuttle to the hotel you are welcome to leave your car at the venue and pick it up the next morning.</OptionText>
                </p>
                <p style={{textAlign: "center"}}>
                    <p><OptionLabel>Can I take pictures?</OptionLabel></p>
                    <OptionText>
                        <p>We have hired a professional photographer to take pictures. During the ceremony, we prefer our guests to be fully present. We will share our photographer's pictures at a later date.</p>
                        <p>You are more than welcome to take as many pictures after the ceremony and please upload them to our website. We look forward to seeing the pictures you take.</p>
                    </OptionText>
                </p>
                <p style={{textAlign: "center"}}>
                    <p><OptionLabel>Are children invited?</OptionLabel></p>
                    <OptionText>
                        <p>Although we adore your children, we’ve elected to limit our ceremony and reception to adults only.</p>
                    </OptionText>
                </p>
                <p style={{textAlign: "center"}}>
                    <p><OptionLabel>Can I bring a date?</OptionLabel></p>
                    <OptionText>
                        <p>We can only accommodate people formally listed on your invitation. Thank you for understanding.</p>
                    </OptionText>
                </p>
            </QAContainer>
        </DFC>
    )

}