import styled from 'styled-components';



const MusicRequestLineItemContainer = styled.div`
    font-family: 'Just Another Hand';
    font-size: 2.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding: .6rem;
    margin: 2%;
    border-top: ${props => props.first ? 'none' : '2px solid black'};
`

const MusicRequestLineItemContainerLeft = styled.div`
    display: flex;
    width: 80%;
    justify-content: space-around;
`

const MusicRequestLineItemContainerRight = styled.div`
    display: flex;
    width: 20%;
    justify-content: end;
`


export default function MusicRequestLineItem({data, first, removeCallback}) {

    return(
        <MusicRequestLineItemContainer first={first}>
            <MusicRequestLineItemContainerLeft>
                <label>{data.SONGREQUESTTITLE}</label>
                <label>{data.SONGREQUESTARTIST}</label>
            </MusicRequestLineItemContainerLeft>
            <MusicRequestLineItemContainerRight>
                <button onClick={() => {removeCallback(data)}}>-</button>
            </MusicRequestLineItemContainerRight>
        </MusicRequestLineItemContainer>
    );
}