import styled from 'styled-components';
import useWindowSize from '../components/hooks/useWindowSize';
import { useContext } from 'react'
import { SupportContextData } from '../components/support_context';


const DFC = styled.div`
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
`

const TravelContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    background-color: white;
    border-radius: 20px;
    border: 1px solid black;
    padding: 12px;
    max-height: ${props => (props.narrow ?  "" : (props.nottooshort ? "58vh" : (props.short ? "30vh" : "68vh")))};
    overflow-y: auto;
`

const StyledLabel = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 2.2em;
    font-weight: 500;
    text-decoration-thickness: 2px;
    color: black;
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 2px;
`

const StyledText = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 2em;
    font-weight: 400;
    color: black;
    text-align: center;
`

const OptionText = styled.div`
    font-size: 14px;
    font-weight: 500;
`

const OptionLabel = styled.div`
    font-size: 18px;
    font-weight: 500;
`
const ListLink = styled.a`
    font-size: 16px;
    padding-left: 20px;
    text-decoration: underline;
    color: black;
`



export default function MobileTravel() {

    const supportContext = useContext(SupportContextData)

    const { width, height } = useWindowSize();
    const narrow = width < 1280;
    const short = height < 700;
    const nottooshort = (height >= 700 && height < 950);


    return (
        <DFC>
            <TravelContainer narrow={narrow} short={short} nottooshort={nottooshort}>
                <div style={{textAlign: "center"}}>
                    <StyledLabel>Accomodations</StyledLabel>
                </div>
                <div style={{textAlign: "center"}}>
                    <p>
                        <OptionLabel>Bonomo - Baroody Wedding special group rate at:</OptionLabel>
                    </p>
                    <p>
                        <OptionLabel>Fairfield Inn & Suites</OptionLabel>
                        <img src={supportContext.fairfield_inn} />
                        <OptionText>5280 Willow Pl, Verona, NY 13478</OptionText>
                        <OptionText>$169 USD - $174 USD plus tax per night</OptionText>
                        {/*<a href='https://www.marriott.com/event-reservations/reservation-link.mi?id=1692122800230&key=GRP&app=resvlink' target="_blank">Book your group rate for Bonomo Baroody wedding</a>*/}
                        <br/>
                        <OptionLabel style={{color: "blue"}}>Our hotel block is full at this time. Please contact us if you need a room.</OptionLabel>
                    </p>
                    <p>
                        <OptionText>Located 14 mins from Hayloft on the Arch wedding venue, across the street from the Turning Stone Resort and Casino</OptionText>
                        <br/>
                        <OptionText>There will be a complimentary shuttle service from this hotel, to the venue, and back</OptionText>
                        <br/>
                        <OptionText>Rooms can be booked for 1, 2 or 3 nights</OptionText>
                        <br/>
                        <OptionText>The hotel includes complimentary shuttles that run to and from the casino</OptionText>
                    </p>
                    <p>
                        <OptionText><b>Start Date - </b> Friday, August 30, 2024</OptionText>
                        <OptionText><b>End Date - </b> Monday, September 2, 2024</OptionText>
                        <OptionText><b>Last Day to Book - </b> Tuesday, July 30, 2024</OptionText>
                    </p>
                </div>
                <hr style={{width: "80%"}}/>
                <div style={{textAlign: "center"}}>
                    <p><OptionLabel>Other Hotel Options near Hayloft on the Arch:</OptionLabel></p>
                </div>
                <div style={{textAlign: "left"}}>
                    <ul>
                        <li style={{marginTop:"14px"}}><ListLink href='https://www.turningstone.com/accommodations' target="_blank">Turning Stone Resort and Casino (Verona, NY)</ListLink></li>
                        <li style={{marginTop:"14px"}}><ListLink href='https://www.hilton.com/en/hotels/syrvehx-hampton-verona-at-turning-stone/' target="_blank">Hampton Inn Verona at Turning Stone (Verona, NY)</ListLink></li>
                        <li style={{marginTop:"14px"}}><ListLink href='https://vernondowns.com/hotel/' target="_blank">Vernon Downs Casino & Hotel (Vernon, NY)</ListLink></li>
                    </ul>
                </div>
                <hr style={{width: "80%"}}/>
                <div style={{textAlign: "center"}}>
                    <p><OptionLabel>Things to Do</OptionLabel></p>
                </div>
                <div style={{textAlign: "left"}}>
                    <ul>
                        <li style={{marginTop:"14px"}}><ListLink href='https://www.nickydoodles.com/' target="_blank">Nicky Doodle's - Ice Cream - 1.4 Miles from Fairfield Inn</ListLink></li>
                        <li style={{marginTop:"14px"}}><ListLink href='https://nysfair.ny.gov/' target="_blank">The Great NY State Fair - 40 mins from Verona, NY</ListLink></li>
                        <li style={{marginTop:"14px"}}><ListLink href='https://www.turningstone.com' target="_blank">Turning Stone Resort & Casino - Golf, Spa, Nightlife, Dining</ListLink></li>
                        <li style={{marginTop:"14px"}}><ListLink href='https://www.recoverysportsgrill.com/verona/' target="_blank">Recovery Sports Grill Vernon, NY - Located next to Fairfield Inn & Suites</ListLink></li>
                    </ul>
                </div>
            </TravelContainer>
        </DFC>
    )
}