import styled from 'styled-components';



export const NeonText = styled.h1`
    
    background-image: radial-gradient(
        ellipse 25% 35% at 50% 50%,
        #6b3818,
        transparent
    );
    letter-spacing: 2;
    font-family: 'Tilt Neon', cursive;
    color: #fff3e6;
    text-shadow: 0 0 0.6rem #fff3e6, 0 0 1.5rem #ffb565,
        -0.2rem 0.1rem 1rem #ffb565, 0.2rem 0.1rem 1rem #ffb565,
        0 -0.5rem 2rem #ff9224, 0 0.5rem 3rem #ff9224;
    animation: shine 2s forwards, flicker 3s infinite;
    text-align: center;

    @keyframes blink {
        0%,
        22%,
        36%,
        75% {
          color: #fff3e6;
          text-shadow: 0 0 0.6rem #fff3e6, 0 0 1.5rem #ffb565,
            -0.2rem 0.1rem 1rem #ffb565, 0.2rem 0.1rem 1rem #ffb565,
            0 -0.5rem 2rem #ff9224, 0 0.5rem 3rem #ff9224;
        }
        28%,
        33% {
          color: #ffb565;
          text-shadow: none;
        }
        82%,
        97% {
          color: #ffaf59;
          text-shadow: none;
        }
      }

    animation: shine 2s forwards, blink 2s 2s 2;

    @keyframes shine {
        0% {
          color: #6b3818;
          text-shadow: none;
        }
        100% {
          color: #fff3e6;
          text-shadow: 0 0 0.6rem #fff3e6, 0 0 1.5rem #ffb565,
            -0.2rem 0.1rem 1rem #ffb565, 0.2rem 0.1rem 1rem #ffb565,
            0 -0.5rem 2rem #ff9224, 0 0.5rem 3rem #ff9224;
        }
    }

    @keyframes flicker {
        from {
          opacity: 1;
        }
      
        4% {
          opacity: 0.9;
        }
      
        6% {
          opacity: 0.85;
        }
      
        8% {
          opacity: 0.95;
        }
      
        10% {
          opacity: 0.9;
        }
      
        11% {
          opacity: 0.922;
        }
      
        12% {
          opacity: 0.9;
        }
      
        14% {
          opacity: 0.95;
        }
      
        16% {
          opacity: 0.98;
        }
      
        17% {
          opacity: 0.9;
        }
      
        19% {
          opacity: 0.93;
        }
      
        20% {
          opacity: 0.99;
        }
      
        24% {
          opacity: 1;
        }
      
        26% {
          opacity: 0.94;
        }
      
        28% {
          opacity: 0.98;
        }
      
        37% {
          opacity: 0.93;
        }
      
        38% {
          opacity: 0.5;
        }
      
        39% {
          opacity: 0.96;
        }
      
        42% {
          opacity: 1;
        }
      
        44% {
          opacity: 0.97;
        }
      
        46% {
          opacity: 0.94;
        }
      
        56% {
          opacity: 0.9;
        }
      
        58% {
          opacity: 0.9;
        }
      
        60% {
          opacity: 0.99;
        }
      
        68% {
          opacity: 1;
        }
      
        70% {
          opacity: 0.9;
        }
      
        72% {
          opacity: 0.95;
        }
      
        93% {
          opacity: 0.93;
        }
      
        95% {
          opacity: 0.95;
        }
      
        97% {
          opacity: 0.93;
        }
      
        to {
          opacity: 1;
        }
    }
`;



export default function NeonSign({text}) {



    return (
        <NeonText>
            {text}
        </NeonText>
    )
}