import { useState, useEffect } from 'react';
import { NeonText } from "./neon_text";



const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));

    return [days + 1, hours, minutes];
}



const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());


    useEffect(() => {
        const interval = setInterval( () => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000 * 60);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
}


export default function CountDown({targetDate}) {

    const [days, hours, minutes] = useCountdown(targetDate);

    if (days + hours + minutes <= 0) {
        return(
            <NeonText>
                We're Married!
            </NeonText>
        )
    } else {
        return (
            <NeonText>
                {/*{days} Days, {hours} H & {minutes} M*/}
                {days} Days
            </NeonText>
        )
    }
}