import styled from 'styled-components';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import MusicRequestLineItem from './music_request_line_item';
import { SupportContextData } from './support_context';


const DFC = styled.div`
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: center;
`

const MusicRequestContainer = styled.div`
    width: 100%;
    max-width: 500px;
    max-height: 66vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background: url("${props => props.url}");
    background-size: cover;
    border-radius: 8px;
    padding-bottom: 30px;
`

const MusicRequestInputContainer = styled.div`
    padding: .6rem;
    max-width: 500px;
    width: 100%;
    margin-top: 42px;
`

const MusicRequestInput = styled.input`
    font-size: 20px;
    border: none;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
    &:focus {
        outline: none;
        outline: 2px solid black;
    }
`

const MusicRequestInputs = styled.div`
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 26px;
`

const MusicRequestsBox = styled.div`
    border: 2px solid black;
    width: 78%;
    padding: 2%;
    border-radius: 10px;
    transform: rotate(-4deg);
    margin-top: 18%;
    margin-right: 3%;
    max-height: 440px;
    overflow: auto;
`

const MusicRequestPlaceholder = styled.div`
    font-family: 'Just Another Hand';
    font-size: 2rem;
    text-align: center;
`

const MusicRequestBtn = styled.div`
    width: 30%;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    background: #FFF;
    padding: 10px 8px 10px 8px;
    opacity: 0.8;
    border-radius: 6px;
    outline: 2px solid #454545;
    &:hover {
        outline: 2px solid black;
        background: #EEE
    }
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
`;



export default function MusicRequest() {

    const [ songTitle, setSongTitle ] = useState();
    const [ songArtist, setSongArtist ] = useState();
    const [ songRequests, setSongRequests ] = useState([]);
    const [ messageText, setMessageText ] = useState("(Your requests will show here)")

    const supportContext = useContext(SupportContextData);

    const handleSumitRequests = () => {
        try {
            axios.post("/api/song-requests/new", {
                'song_requests': songRequests
            }).then((response) => {
                console.log(response.data)
                if (response.data.message = 'Done') {
                    setSongRequests([])
                    setMessageText("Your requests have been received")
                } else {
                    setMessageText("Error processing request.. try again later")
                }
            });
            setSongRequests([]);
        } catch (e) {
            if (e.response.status === 500) {
                console.log("could not add song requests");
            }
        }
    }

    const handleAddSongRequest = () => {
        setSongRequests([...songRequests, {'SONGREQUESTTITLE': songTitle, 'SONGREQUESTARTIST': songArtist}]);
        setSongTitle("");
        setSongArtist("");
    };

    const removeSongRequest = (data) => {
        console.log(data);
        var array = songRequests.slice();
        var index = array.indexOf(data);
        if (index !== -1) {
            array.splice(index, 1);
            setSongRequests(array);
        }
        setMessageText("(Your requests will show here)")
    };

    if (!supportContext) {
        return (
            <></>
        )
    } else {
        return (
            <DFC>
            <MusicRequestInputContainer>
                <div style={{textAlign: "center", width: "100%", fontSize: "24px", fontWeight: 600}}>What song will get you on the dance floor?</div>
                <MusicRequestInputs>
                    <MusicRequestInput type="text" placeholder="Song title" value={songTitle} onChange={(e) => setSongTitle(e.target.value)} />
                    <MusicRequestInput type="text" placeholder="Arist" value={songArtist} onChange={(e) => setSongArtist(e.target.value)} />
                    <MusicRequestBtn onClick={handleAddSongRequest}>Add &#62;&#62;</MusicRequestBtn>
                </MusicRequestInputs>
                <div style={{textAlign: "center", width: "100%", fontSize: "16px", fontWeight: 500}}>(We will make a list of all suggestions and give you the opportunity to vote for your favorites when you RSVP)</div>
            </MusicRequestInputContainer>
            <MusicRequestContainer url={supportContext.note}>
                <MusicRequestsBox>
                    {songRequests.length > 0 ?
                        songRequests.map((data =>
                            <MusicRequestLineItem key={data.index} data={data} first={songRequests.indexOf(data) == 0} removeCallback={removeSongRequest}/>
                        ))
                    :
                        <MusicRequestPlaceholder>{messageText}</MusicRequestPlaceholder>
                    }
                </MusicRequestsBox>
                <MusicRequestBtn onClick={handleSumitRequests}>Submit</MusicRequestBtn>
            </MusicRequestContainer>
            </DFC>
        );
    }
}

