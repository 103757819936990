import styled from 'styled-components';
import { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import { PhotoContextData } from '../components/photo_context';
import { GalleryViewContainer, GalleryImageContainer, GalleryImg, ChangePicture, customStyles } from '../components/ropes';
import { SupportContextData } from '../components/support_context';
import useWindowSize from '../components/hooks/useWindowSize';
import { PageImageContainer, PageImage } from '../components/containers';

const GalleryContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5vh;
`

const GalleryRope = styled.img`
    z-index: 1;
    width: 100%;
`

const GalleryRow = styled.div`
    display: flex;
    flex-direction: column;
`

const GalleryRopeImageContainer = styled.div`
    z-index: 0;
    margin-top: -22px;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: space-around;
`;

const GalleryImage = styled.img`
    max-width: 10vw;
    max-height: 20vh;
    &:hover {
        cursor: pointer;
    }
`

export default function Gallery() {

    const { width, height } = useWindowSize();
    const narrow = width < 1440;
    const short = height < 800;

    const fullPhotoContext = useContext(PhotoContextData);
    const photoContext = fullPhotoContext.photoUrls;

    const supportContext = useContext(SupportContextData)

    const [ modalPicture, setModalPicture ] = useState("");
    const [ modalIsOpen, setIsOpen ] = useState(false);
    const [ modalPictureCounter, setModalPictureCounter ] = useState(0);

    useEffect(() => {
        if(photoContext) {
            setModalPicture(photoContext[modalPictureCounter % photoContext.length][Object.keys(photoContext[modalPictureCounter % photoContext.length])[0]].gallery);
        }
    }, [modalPictureCounter])


    function openModal(modalPictureIndex) {
        setModalPicture("");
        setModalPictureCounter(modalPictureIndex);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function modalNext() {
        setModalPictureCounter(p => p+1);
    }

    function modalPrevious() {
        setModalPictureCounter(p => p > 0 ? p-1 : photoContext.length);
    }

  
    if (!photoContext || !supportContext) {
        return (
            <></>
        )
    } else {
        return (
            <>
            <PageImageContainer narrow={narrow} short={short}>
            <GalleryContainer>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[0][Object.keys(photoContext[0])[0]].polaroid} onClick={() => openModal(0)}/>
                        <GalleryImage src={photoContext[1][Object.keys(photoContext[1])[0]].polaroid} onClick={() => openModal(1)}/>
                        <GalleryImage src={photoContext[2][Object.keys(photoContext[2])[0]].polaroid} onClick={() => openModal(2)}/>
                        <GalleryImage src={photoContext[3][Object.keys(photoContext[3])[0]].polaroid} onClick={() => openModal(3)}/>
                        <GalleryImage src={photoContext[4][Object.keys(photoContext[4])[0]].polaroid} onClick={() => openModal(4)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[5][Object.keys(photoContext[5])[0]].polaroid} onClick={() => openModal(5)}/>
                        <GalleryImage src={photoContext[6][Object.keys(photoContext[6])[0]].polaroid} onClick={() => openModal(6)}/>
                        <GalleryImage src={photoContext[7][Object.keys(photoContext[7])[0]].polaroid} onClick={() => openModal(7)}/>
                        <GalleryImage src={photoContext[8][Object.keys(photoContext[8])[0]].polaroid} onClick={() => openModal(8)}/>
                        <GalleryImage src={photoContext[9][Object.keys(photoContext[9])[0]].polaroid} onClick={() => openModal(9)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[10][Object.keys(photoContext[10])[0]].polaroid} onClick={() => openModal(10)}/>
                        <GalleryImage src={photoContext[11][Object.keys(photoContext[11])[0]].polaroid} onClick={() => openModal(11)}/>
                        <GalleryImage src={photoContext[12][Object.keys(photoContext[12])[0]].polaroid} onClick={() => openModal(12)}/>
                        <GalleryImage src={photoContext[13][Object.keys(photoContext[13])[0]].polaroid} onClick={() => openModal(13)}/>
                        <GalleryImage src={photoContext[14][Object.keys(photoContext[14])[0]].polaroid} onClick={() => openModal(14)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[15][Object.keys(photoContext[15])[0]].polaroid} onClick={() => openModal(15)}/>
                        <GalleryImage src={photoContext[16][Object.keys(photoContext[16])[0]].polaroid} onClick={() => openModal(16)}/>
                        <GalleryImage src={photoContext[17][Object.keys(photoContext[17])[0]].polaroid} onClick={() => openModal(17)}/>
                        <GalleryImage src={photoContext[18][Object.keys(photoContext[18])[0]].polaroid} onClick={() => openModal(18)}/>
                        <GalleryImage src={photoContext[19][Object.keys(photoContext[19])[0]].polaroid} onClick={() => openModal(19)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[20][Object.keys(photoContext[20])[0]].polaroid} onClick={() => openModal(0)}/>
                        <GalleryImage src={photoContext[21][Object.keys(photoContext[21])[0]].polaroid} onClick={() => openModal(21)}/>
                        <GalleryImage src={photoContext[22][Object.keys(photoContext[22])[0]].polaroid} onClick={() => openModal(22)}/>
                        <GalleryImage src={photoContext[23][Object.keys(photoContext[23])[0]].polaroid} onClick={() => openModal(23)}/>
                        <GalleryImage src={photoContext[24][Object.keys(photoContext[24])[0]].polaroid} onClick={() => openModal(24)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[25][Object.keys(photoContext[25])[0]].polaroid} onClick={() => openModal(25)}/>
                        <GalleryImage src={photoContext[26][Object.keys(photoContext[26])[0]].polaroid} onClick={() => openModal(26)}/>
                        <GalleryImage src={photoContext[27][Object.keys(photoContext[27])[0]].polaroid} onClick={() => openModal(27)}/>
                        <GalleryImage src={photoContext[28][Object.keys(photoContext[28])[0]].polaroid} onClick={() => openModal(28)}/>
                        <GalleryImage src={photoContext[29][Object.keys(photoContext[29])[0]].polaroid} onClick={() => openModal(29)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[30][Object.keys(photoContext[30])[0]].polaroid} onClick={() => openModal(30)}/>
                        <GalleryImage src={photoContext[31][Object.keys(photoContext[31])[0]].polaroid} onClick={() => openModal(31)}/>
                        <GalleryImage src={photoContext[32][Object.keys(photoContext[32])[0]].polaroid} onClick={() => openModal(32)}/>
                        <GalleryImage src={photoContext[33][Object.keys(photoContext[33])[0]].polaroid} onClick={() => openModal(33)}/>
                        <GalleryImage src={photoContext[34][Object.keys(photoContext[34])[0]].polaroid} onClick={() => openModal(34)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[35][Object.keys(photoContext[35])[0]].polaroid} onClick={() => openModal(35)}/>
                        <GalleryImage src={photoContext[36][Object.keys(photoContext[36])[0]].polaroid} onClick={() => openModal(36)}/>
                        <GalleryImage src={photoContext[37][Object.keys(photoContext[37])[0]].polaroid} onClick={() => openModal(37)}/>
                        <GalleryImage src={photoContext[38][Object.keys(photoContext[38])[0]].polaroid} onClick={() => openModal(38)}/>
                        <GalleryImage src={photoContext[39][Object.keys(photoContext[39])[0]].polaroid} onClick={() => openModal(39)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[40][Object.keys(photoContext[40])[0]].polaroid} onClick={() => openModal(40)}/>
                        <GalleryImage src={photoContext[41][Object.keys(photoContext[41])[0]].polaroid} onClick={() => openModal(41)}/>
                        <GalleryImage src={photoContext[42][Object.keys(photoContext[42])[0]].polaroid} onClick={() => openModal(42)}/>
                        <GalleryImage src={photoContext[43][Object.keys(photoContext[43])[0]].polaroid} onClick={() => openModal(43)}/>
                        <GalleryImage src={photoContext[44][Object.keys(photoContext[44])[0]].polaroid} onClick={() => openModal(44)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
                <GalleryRow>
                    <GalleryRope src={supportContext.gallery_rope}/>
                    <GalleryRopeImageContainer>
                        <GalleryImage src={photoContext[45][Object.keys(photoContext[45])[0]].polaroid} onClick={() => openModal(45)}/>
                        <GalleryImage src={photoContext[46][Object.keys(photoContext[46])[0]].polaroid} onClick={() => openModal(46)}/>
                        <GalleryImage src={photoContext[47][Object.keys(photoContext[47])[0]].polaroid} onClick={() => openModal(47)}/>
                        <GalleryImage src={photoContext[48][Object.keys(photoContext[48])[0]].polaroid} onClick={() => openModal(48)}/>
                        <GalleryImage src={photoContext[49][Object.keys(photoContext[49])[0]].polaroid} onClick={() => openModal(49)}/>
                    </GalleryRopeImageContainer>
                </GalleryRow>
            </GalleryContainer>
            </PageImageContainer>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="GalleryImage"
            >
                <GalleryViewContainer>
                    <GalleryViewContainer>
                        <ChangePicture onClick={modalPrevious}>&#60;</ChangePicture>
                        <GalleryImageContainer>
                            <GalleryImg src={modalPicture}/>
                        </GalleryImageContainer>
                        <ChangePicture onClick={modalNext}>&#62;</ChangePicture>
                    </GalleryViewContainer>
                </GalleryViewContainer>
            </Modal>
            </>
        );
    }
}