import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledNavLink = styled(Link)`
    font-family: 'Just Another Hand', cursive;
    font-size: 2.2em;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    letter-spacing: .2rem;
    color: #8c320460;
    /* text-shadow: 0px 1px 3px #35353578; */
    text-shadow: 0 2px 2px #240c00, 0 0px 8px #541c00, 0 -5px 16px #ff8000, 0 -9px 30px #805649;

`