import { useState } from 'react';
import Paths from "./Paths";
import Doors from "./components/doors";
import { SupportContext } from './components/support_context';
import { SupportContextData } from './components/support_context';
import useWindowSize from './components/hooks/useWindowSize';
import ClipLoader from 'react-spinners/ClipLoader';
import MobilePaths from './MobilePaths';

function App() {

  const [ firstOpen, setFirstOpen ] = useState(true);

  const { width, height } = useWindowSize();
  const shrunk = width < 1100;
  const short = height < 600;

  if (!SupportContextData) {
    return (
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", background: "black", height: "100%", width: "100%"}}>
          <ClipLoader
            color={"white"}
            loading={true}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
      </div>
    )
  } else {
    if (shrunk | short) {
      return (
        <SupportContext>
          <MobilePaths />
        </SupportContext>
      )
    } else {
      return (
        <SupportContext>
        {firstOpen && 
          <div style={{position: "absolute", height: "100vh", width: "100vw", overflow: 'hidden'}}>
              <Doors doorsCallback={setFirstOpen}/>
          </div>
        }
        <Paths/>
        </SupportContext>
      );
    }
  }
}

export default App;
