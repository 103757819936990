import { useContext, useEffect } from 'react';
import useWindowSize from '../components/hooks/useWindowSize';
import styled from 'styled-components';
import { SupportContextData } from '../components/support_context';
import { PageImageContainer, PageImage } from '../components/containers';

const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};


const RegistryContainer = styled.div`
    max-height: 76vh;
    overflow-y: auto;
    margin-top: 20px;
`

const RegistryImage = styled.img`
    max-width: 100%;
    height: auto;
`



export default function Registry() {

    /*useScript('//www.myregistry.com//Visitors/GiftList/iFrames/EmbedRegistry.ashx?r=xV2Z1iFW0l93-I3W38Sigg2&v=2');

    const { width, height } = useWindowSize();
    const narrow = width <= 1500;

    return (
        <>
            <div style={{height: '56vh', marginTop: '20px', background: 'url("/static/assets/registry_back.webp")', backgroundSize: '100% 100%', padding: '10vh 4vw 10vh 4vw'}}>
              <div style={{height: '55vh', marginTop: '26px', overflowY: 'scroll'}}>
                  <iframe id="myregsitry_embeded_iframe" src="https://www.myregistry.com/ExternalApps/EmbededVistorView/v2/Visitors/GiftList.aspx?registryId=3877959&amp;pageSize=10000" frameborder="0" style={{width: '100%', height: `${narrow ? '15150px' : '9000px'}`, display: 'block'}}></iframe>
              </div>
            </div>
        </>
    )*/

    // https://www.myregistry.com/wedding-registry/maggie-bonomo-and-mike-baroody-manlius-ny/3877959/giftlist

    const { width, height } = useWindowSize();
    const narrow = width < 1440;
    const short = height < 800;

    const supportContext = useContext(SupportContextData)

    if (!supportContext) {
      <></>
    } else {
      return (
        <PageImageContainer narrow={narrow} short={short}>
          <PageImage src={supportContext.registry} />
          <a href='https://www.target.com/gift-registry/gift-giver?registryId=36790bb0-35fb-11ee-8eb2-3b66df45fbad&clkid=45d0cfbaN916911eeb97913a0d7449a52&cpng=PTID1&lnm=81938&afid=Myregistry%20LLC&ref=tgt_adv_xasd0002&type=WEDDING' target="_blank">
            <PageImage src={supportContext.target_registry} />
          </a>
          <a href='https://www.amazon.com/wedding/registry/3OPS6XZZGBVI6?tag=myregistrysync-20' target="_blank">
            <PageImage src={supportContext.amazon_registry} />
          </a>
        </PageImageContainer>
      )
    }
}