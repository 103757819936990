import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import { PhotoContextData } from './photo_context';


const RopesContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 1000px;
    width: 300px;


    animation: ${props => props.animation_type ? RopeAnimationDown(props.offset) : RopeAnimationUp(props.offset)} 8s 1 forwards;
`

const RopeAnimationUp = (offset) => keyframes`
    from {
        transform: translateY(calc(50% + 100px));
    }
    20% {
        transform: translateY(calc(${offset}% - 100px));
    }
    80% {
        transform: translateY(calc(${offset}% - 100px));
    }
    to {
        transform: translateY(calc(-50% - 200px));
    }
`;

const RopeAnimationDown = (offset) => keyframes`
    from {
        transform: translateY(calc(-50% - 200px));
    }
    20% {
        transform: translateY(calc(${offset}% - 100px));
    }
    80% {
        transform: translateY(calc(${offset}% - 100px));
    }
    to {
        transform: translateY(calc(50% + 100px));
    }
`;

const RopesRow = styled.div`
    position: relative;
    z-index: 3;
    display: flex;
    height: 400px;
`

const ImageRow = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin-top: -20px;
    margin-bottom: -20px;
`

const PolaroidImage = styled.img`
    height: 200px;
    &:hover {
        cursor: pointer;
    }
`

const RopeImage = styled.img`
    width: 40px;
    height: 1200px;
`

const RopesBox = styled.div`
    display: flex;
    align-items: ${props => props.top ? "end" : "start"};
    justify-content: center;
    width: 100px;
    min-width: 100px;
`

function ConfigSwitch(config, top, ropeImages) {
    /*let img_rope_left_down = '/static/assets/ropes/rope_one_left_down.webp';
    let img_rope_left_up = '/static/assets/ropes/rope_one_left_up.webp';
    let img_rope_right_down = '/static/assets/ropes/rope_one_right_down.webp';
    let img_rope_right_up = '/static/assets/ropes/rope_one_right_up.webp';*/
    let img_rope_left_down = ropeImages.rope_one_left_down;
    let img_rope_left_up = ropeImages.rope_one_left_up;
    let img_rope_right_down = ropeImages.rope_one_right_down;
    let img_rope_right_up = ropeImages.rope_one_right_up;

    switch(config) {
        case '100':
            return (
                <RopesRow>
                    <RopesBox top={top}>
                        <RopeImage src={top ? img_rope_left_down : img_rope_left_up}/>
                    </RopesBox>
                    <RopesBox top={top}></RopesBox>
                    <RopesBox top={top}></RopesBox>
                </RopesRow>
            );
        case '010':
            return (
                <RopesRow>
                    <RopesBox top={top}></RopesBox>
                    <RopesBox top={top}>
                        <RopeImage src={top ? img_rope_right_down : img_rope_right_up}/>
                    </RopesBox>
                    <RopesBox top={top}></RopesBox>
                </RopesRow>
            );
        case '001':
            return (
                <RopesRow>
                    <RopesBox top={top}></RopesBox>
                    <RopesBox top={top}></RopesBox>
                    <RopesBox top={top}>
                        <RopeImage src={top ? img_rope_right_down : img_rope_right_up}/>
                    </RopesBox>
                </RopesRow>
            );
        case '101':
            return (
                <RopesRow>
                    <RopesBox top={top}>
                        <RopeImage src={top ? img_rope_left_down : img_rope_left_up}/>
                    </RopesBox>
                    <RopesBox top={top}></RopesBox>
                    <RopesBox top={top}>
                        <RopeImage src={top ? img_rope_right_down : img_rope_right_up}/>
                    </RopesBox>
                </RopesRow>
            );
        case '000':
                return (
                    <RopesRow>
                        <RopesBox top={top}></RopesBox>
                        <RopesBox top={top}></RopesBox>
                        <RopesBox top={top}></RopesBox>
                    </RopesRow>
                );
        default:
            return (
                <RopesRow>
                    <RopesBox top={top}></RopesBox>
                    <RopesBox top={top}>
                        <RopeImage src={top ? img_rope_right_down : img_rope_right_up}/>
                    </RopesBox>
                    <RopesBox top={top}></RopesBox>
                </RopesRow>
            );
    }
}


const top_rope_options = ['010'];
const bottom_rope_options = ['010', '000'];

function getRandomRopeConfig(top) {
    if (top) {
        return top_rope_options[Math.floor(Math.random() * top_rope_options.length)]
    } else {
        return bottom_rope_options[Math.floor(Math.random() * bottom_rope_options.length)]
    }
}

export const GalleryViewContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-height: 80vh;
    max-width: 70vw;
`

export const GalleryImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 80vh;
    max-width: 60vw;
`;

export const GalleryImg = styled.img`
    max-height: 100%;
    max-width: 100%;
`;

export const ChangePicture = styled.div`
    width: 30px;
    height: 30px;
    cursor: pointer;
    text-align: center;
    font-size: 24px;
    background:#FFF;
    padding: 40px 12px 40px 12px;
    opacity: 0.8;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 6px;
`

function RopeSwap(ropeCallback, top, state, callback) {
    //if(state) {
        // rope selection
        const ropeSelection = getRandomRopeConfig(top);
        ropeCallback(ropeSelection);
    //}
    callback(true);
}


export const customStyles = {
    overlay: {
        backgroundColor: 'rgba(65, 65, 65, 0.65)',
        zIndex: 99
    },
    content: {
      maxWidth: '60vw',
      maxHeight: '80vh',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'none',
      border: 'none'
    },
};

export default function Ropes({offset}) {

    console.log(`Rope offset is ${offset}`);

    const fullPhotoContext = useContext(PhotoContextData);
    const photoContext = fullPhotoContext.photoUrls;
    const ropeContext = fullPhotoContext.ropeUrls;

    const [ pictureCounter, setPictureCounter ] = useState(Math.floor(Math.random() * 50));
    const [ polaroidPicture, setPolaroidPicture ] = useState();
    const [ modalPicture, setModalPicture ] = useState("");

    const [ topRopeConfig, setTopRopeConfig ] = useState('101');
    const [ bottomRopeConfig, setBottomRopeConfig ] = useState('101');

    const [ animationBinary, setAnimationBinary ] = useState(true); 
    const [ hasBeenCalled, setHasBeenCalled ] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [ modalPictureCounter, setModalPictureCounter ] = useState(0);

    function openModal() {
        setModalPictureCounter(pictureCounter - 1);
        setModalPicture(photoContext[(pictureCounter - 1) % photoContext.length][Object.keys(photoContext[(pictureCounter - 1) % photoContext.length])[0]].gallery);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function modalNext() {
        setModalPicture(photoContext[(modalPictureCounter + 1) % photoContext.length][Object.keys(photoContext[(modalPictureCounter + 1) % photoContext.length])[0]].gallery);
        increaseModalPictureCounter();
    }

    function modalPrevious() {
        setModalPicture(photoContext[(modalPictureCounter > 0 ? modalPictureCounter - 1 : photoContext.length) % photoContext.length][Object.keys(photoContext[(modalPictureCounter > 0 ? modalPictureCounter - 1 : photoContext.length) % photoContext.length])[0]].gallery);
        decreaseModalPictureCounter();
    }

    function increaseModalPictureCounter() {
        setModalPictureCounter(p => p+1);
        return(modalPictureCounter + 1);
    }

    function decreaseModalPictureCounter() {
        setModalPictureCounter(p => p > 0 ? p-1 : photoContext.length);
        return(modalPictureCounter > 0 ? modalPictureCounter - 1 : photoContext.length);
    }

    useEffect(() => {
        const interval = setInterval(
            function() {
                setAnimationBinary(p => !p);
            }, 10000
        );
        setAnimationBinary(p => !p);
        console.log("Interval set");
        return () => {clearInterval(interval)};
    }, [photoContext])

    useEffect(() => {
        console.log("fullPhotoContext: ", fullPhotoContext);
        console.log("photoContext: ", photoContext);
        if (photoContext) {
            updatePicture();
            if (animationBinary === true) {
                RopeSwap(setTopRopeConfig, true, hasBeenCalled, setHasBeenCalled);
            } else {
                RopeSwap(setBottomRopeConfig, false, hasBeenCalled, setHasBeenCalled);
            }
            console.log("animationBinary useEffect fired");
        }
    }, [animationBinary])

    function updatePicture() {
        setPictureCounter(p => p+1);
        setPolaroidPicture(photoContext[pictureCounter % photoContext.length][Object.keys(photoContext[pictureCounter % photoContext.length])[0]].polaroid);
    }

    if (!photoContext || !polaroidPicture || !ropeContext) {
        return (
            <RopesContainer />
        );
    } else {
        return (
            <>
            <RopesContainer offset={offset} animation_type={animationBinary}>
                {ConfigSwitch(topRopeConfig, true, ropeContext)}
                <ImageRow>
                    <PolaroidImage src={polaroidPicture} onClick={openModal}/>
                </ImageRow>
                {ConfigSwitch(bottomRopeConfig, false, ropeContext)}
            </RopesContainer>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Image"
            >
                <GalleryViewContainer>
                    <GalleryViewContainer>
                        <ChangePicture onClick={modalPrevious}>&#60;</ChangePicture>
                        <GalleryImageContainer>
                            <GalleryImg src={modalPicture}/>
                        </GalleryImageContainer>
                        <ChangePicture onClick={modalNext}>&#62;</ChangePicture>
                    </GalleryViewContainer>
                </GalleryViewContainer>
            </Modal>
            </>
        )
    }
}