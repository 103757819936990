import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useContext } from 'react';
import ToolbarWrapper from "./components/toolbar";
import Home from "./pages/home";
import Story from "./pages/story";
import Gallery from "./pages/gallery";
import WeddingParty from "./pages/wedding_party";
import Itinerary from "./pages/itinerary";
import Registry from "./pages/registry";
import RSVP from "./pages/rsvp";
import Music from "./pages/music";
import { SupportContextData } from "./components/support_context";
import MobileItinerary from './pages/mobile_itinerary';
import MobileQA from './pages/mobile_qa';
import MobileTravel from './pages/mobile_travel';




export default function Paths() {

    const supportContext = useContext(SupportContextData)

    if (!supportContext) {
        return (
            <></>
        )
    } else {
        return (
            <div style={{maxHeight: "100svh", maxWidth: "100vw", height: "100svh", backgroundImage: `url(${supportContext.wood_background})`, backgroundSize: "cover", overflow: 'hidden'}}>
                <Router>
                    <Routes>
                        <Route exact path="/" element={ <ToolbarWrapper component={ Home } /> } />
                        <Route exact path="/story" element={ <ToolbarWrapper component={ Story } /> } />
                        <Route exact path="/gallery" element={ <ToolbarWrapper component={ Gallery } /> } />
                        <Route exact path="/wedding-party" element={ <ToolbarWrapper component={ WeddingParty } /> } />
                        <Route exact path="/travel" element={ <ToolbarWrapper component={ MobileTravel } /> } />
                        <Route exact path="/schedule" element={ <ToolbarWrapper component={ Itinerary } /> } />
                        <Route exact path="/qa" element={ <ToolbarWrapper component={ MobileQA } /> } />
                        <Route exact path="/registry" element={ <ToolbarWrapper component={ Registry } /> } />
                        <Route exact path="/rsvp" element={ <ToolbarWrapper component={ RSVP } /> } />
                        <Route exact path="/music" element={ <ToolbarWrapper component={ Music } /> } />
                    </Routes>
                </Router>
        </div>
        )
    }
}