import { NeonText } from "../components/neon_text";
import CountDown from "../components/countdown_clock";
import { SupportContextData } from "../components/support_context";
import { useContext, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from 'react-modal';
import styled from 'styled-components';




const PopUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.1em;
    padding: 2.2em;
    background: #EEE;
    border-radius: 8px;
    background-image: url(${props => props.url});
    background-size: cover;
`

const PopUpMessage = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 2.2em;
    font-family: 'Just Another Hand', cursive;
    letter-spacing: .1rem;
    color: #8c320460;
    text-shadow: 0 2px 2px #240c00, 0 0px 8px #541c00, 0 -5px 16px #ff8000, 0 -9px 30px #805649;
`

const PopUpButton = styled.div`
    border-radius: 8px;
    border: 2px solid black;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    background: white;
    &:hover {
        background-color: #858585;
    }
`

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(65, 65, 65, 0.65)',
        zIndex: 99
    },
    content: {
      maxWidth: '86vw',
      maxHeight: '80vh',
      top: '45%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'none',
      border: 'none'
    },
};


const StyledBurger = styled.button`
  position: ${({open}) => open ? 'absolute' : 'relative'};
  top: 5%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  
  &:focus {
    outline: none;
  }
  
  div {
    width: 2rem;
    height: 0.25rem;
    background: black;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
        transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
        opacity: ${({ open }) => open ? '0' : '1'};
        transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
        transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: lightgray;
  height: 45%;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  
  
    width: 70%;

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: black};
    text-decoration: none;
    transition: color 0.3s linear;
    

    font-size: 1.5rem;
    text-align: center;

    &:hover {
      color: grey;
    }
  }
`;

function Burger ({open, setOpen}) {
    return (
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
        </StyledBurger>
    )
}

function Menu ({open}) {
    return (
        <StyledMenu open={open}>
            <a href="/">
                <span role="img" aria-label="about us"></span>
                About us
            </a>
            <a href="/">
                <span role="img" aria-label="price"></span>
                Pricing
                </a>
            <a href="/">
                <span role="img" aria-label="contact"></span>
                Contact
                </a>
        </StyledMenu>
    )
}


export default function Mobile () {

    const supportContext = useContext(SupportContextData)

    const [ modalIsOpen, setModalIsOpen ] = useState(true);

    function openModal() {
        setModalIsOpen(true);
    }

    function closeModal() {
        setModalIsOpen(false);
    }

    const [ burgerOpen, setBurgerOpen ] = useState(false);



    if (!supportContext) {
        return(
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", background: "black", height: "100vh", width: "100vw"}}>
                <ClipLoader
                    color={"white"}
                    loading={true}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        )
    } else {
        return (
            <>

            <img src={supportContext.homepage} style={{maxWidth: "90vw"}} />
            <img src={supportContext.details} style={{maxWidth: "90vw"}} />
            {/*<div style={{textAlign: "center", fontSize: "1.4em"}}>Fairfield Inn & Suites</div>
            <div style={{marginTop: ".2em", textAlign: "center", fontSize: "1.1em"}}>5280 Willow Pl, Verona, NY 13478</div>
            <div style={{marginTop: ".6em", textAlign: "center", fontSize: "1em"}}>$169 USD - $174 USD plus tax per night</div>
            <div style={{marginTop: ".4em", marginBottom: "1.4em", textAlign: "center", fontSize: "1em"}}>
                <a href='https://www.marriott.com/event-reservations/reservation-link.mi?id=1692122800230&key=GRP&app=resvlink' target="_blank">Book your group rate for Bonomo Baroody wedding</a>
            </div>
            <img src={supportContext.registry} style={{maxWidth: "90vw"}}/>
            <a href='https://www.target.com/gift-registry/gift-giver?registryId=36790bb0-35fb-11ee-8eb2-3b66df45fbad&clkid=45d0cfbaN916911eeb97913a0d7449a52&cpng=PTID1&lnm=81938&afid=Myregistry%20LLC&ref=tgt_adv_xasd0002&type=WEDDING' target="_blank">
                <img src={supportContext.target_registry} style={{maxWidth: "90vw"}}/>
            </a>
            <a href='https://www.amazon.com/wedding/registry/3OPS6XZZGBVI6?tag=myregistrysync-20' target="_blank">
                <img src={supportContext.amazon_registry} style={{maxWidth: "90vw"}}/>
            </a>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="MobileView"
            >
                <PopUp url={supportContext.wood_background}>
                    <PopUpMessage>To see the full website and travel information please visit our website on a computer, otherwise..</PopUpMessage>
                    <PopUpButton onClick={closeModal}>Continue to mobile</PopUpButton>
                </PopUp>
            </Modal>*/}
        </>
        )
    }
}