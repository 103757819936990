import styled from 'styled-components';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { SupportContextData } from '../components/support_context';
import useWindowSize from '../components/hooks/useWindowSize';


const DFC = styled.div`
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: center;
    margin-top: 42px;
`

const StyledLabel = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 2.2em;
    font-weight: 500;
    text-decoration-thickness: 2px;
    color: black;
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 2px;
`

const StyledGuestName = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 1.8em;
    font-weight: 400;
    text-decoration-thickness: 2px;
    color: black;
    text-align: left;
`

const StyledText = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 1.8em;
    font-weight: 400;
    color: black;
    text-align: center;
`

const OptionText = styled.div`
    font-size: 16px;
    font-weight: 500;
`

const OptionLabel = styled.div`
    font-size: 18px;
    font-weight: 500;
`

const StyledGuestCardText = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 1.8em;
    font-weight: 400;
    color: black;
    text-align: left;
    padding-left: 4px;
    padding-bottom: 2px;
    padding-top: 6px;
`

const DietaryRestrictionsInput = styled.input`
    font-size: 1.1em;
    border: none;
    padding: 2px 1px 2px 1px;
    outline: none;
    border-bottom: 2px solid black;
`


const GuestSearchInput = styled.input`
    font-size: 1.4em;
    border: none;
    padding: 2px 1px 2px 1px;
    outline: none;
    border-bottom: 2px solid black;
`

const GuestSearchBtn = styled.div`
    width: 20%;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    background: #FFF;
    padding: 8px 6px 8px 6px;
    opacity: 1;
    border-radius: 6px;
    outline: 2px solid #454545;
    &:hover {
        outline: 2px solid black;
        background: #EEE
    }
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
`;

const GroupSelectBtn = styled.div`
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    background: #FFF;
    padding: 4px 8px 4px 8px;
    border-radius: 12px;
    outline: 2px solid #454545;
    &:hover {
        outline: 2px solid black;
        background: #EEE
    }
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
`;

const GuestList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const GuestCard = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid black;
`;

const RSVPContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    background-color: white;
    border-radius: 20px;
    border: 1px solid black;
    padding: 12px;
    max-height: ${props => props.short ? "55svh" : (props.narrow ? "" : "66svh")};
    overflow-y: auto;
`

const GroupOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    border: 1px solid black;
    padding: 12px;
    width: 80%;
    max-height: ${props => props.short ? "55svh" : (props.narrow ? "" : "66svh")};
    overflow-y: auto;
`




function GuestSearch({guestListCallback, searchedCallback}) {

    const [guestSearchString, setGuestSearchString] = useState("");

    const handleGuestSearch = async (event) => {
        searchedCallback(true);
        event.preventDefault();
        if (guestSearchString !== '') { 
            try {
                const res = await axios.get(
                    "/api/guests/search", 
                    { 
                        params: {
                            search: guestSearchString
                        }
                    }
                );
                console.log(res.data);
                guestListCallback(res.data['guests']);
            } catch(e) {
                if (e.response.status === 500) {
                    console.log("could not search for guest");
                }
            }
        } else {
            guestListCallback({});
        }
    };

    return (
        <div style={{width: "100%"}}>
            <StyledLabel>RSVP</StyledLabel>
            <br/>
            <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                <StyledText>Enter name of guest:</StyledText>
                <GuestSearchInput style={{maxWidth: "60%"}} type="text" value={guestSearchString} onChange={(e) => setGuestSearchString(e.target.value)}></GuestSearchInput>
            </div>
            <div style={{display: "flex", justifyContent: "center", marginTop: "16px"}}>
                <GuestSearchBtn onClick={handleGuestSearch}>Continue..</GuestSearchBtn>
            </div>
        </div>
    )

}



function GuestGroupCard({guestGroup, selectedGroupCallback}) {
    return(
        <GuestCard>
            <div sytle={{display: "flex", flexDirection: "column"}}>
                {guestGroup.length > 0 ?
                    guestGroup.map((name) =>
                        <>
                            <StyledGuestCardText>{name}</StyledGuestCardText>
                        </>
                    )
                :
                    <></>
                }
            </div>
            <div style={{marginRight: "14px"}}>
                <GroupSelectBtn onClick={() => {selectedGroupCallback(guestGroup)}}>Select</GroupSelectBtn>
            </div>
        </GuestCard>
    )
}


function GuestResponse({name, guestSelections, setGuestSelectionsCallback, setSubmittable}) {

    const [ wedding, setWedding ] = useState();
    const [ dinner, setDinner ] = useState();

    const weddingOptions = [
        {option: 'Graciously Accepts', value: 'yes'},
        {option: 'Regretfully Declines', value: 'no'}
    ]
    const dinnerOptions = [
        {option: 'Steak', value: 'steak'},
        {option: 'Chicken', value: 'chicken'},
        {option: 'Salmon', value: 'salmon'},
        {option: 'Vegetarian', value: 'vegetarian'}
    ]
    const [ dietRestriction, setDietRestriction ] = useState(false);

    const handleWeddingInput = (e) => {
        setWedding(e.target.value);
        const newState = {
            ...guestSelections,
            [name]: {
                ...guestSelections[name],
                wedding: e.target.value
            },
        };
        setGuestSelectionsCallback(newState)
    }

    const handleDinnerInput = (e) => {
        setDinner(e.target.value);
        const newState = {
            ...guestSelections,
            [name]: {
                ...guestSelections[name],
                dinner: e.target.value
            },
        };
        setGuestSelectionsCallback(newState);
    }

    const handleDietRestrictionsInput = (e) => {
        const newState = {
            ...guestSelections,
            [name]: {
                ...guestSelections[name],
                dietRestrictions: e.target.value
            },
        };
        setGuestSelectionsCallback(newState)
    }

    return(
        <>
            <div style={{paddingLeft: "14px", paddingRight: "14px", marginTop: "12px"}}>
                <StyledGuestName style={{marginBottom: "8px"}}>{name}:</StyledGuestName>
                <div>
                    <OptionText style={{marginBottom: "8px"}}>Wedding:</OptionText>
                    <div style={{display: "flex", flexDirection: "column", gap: "10px", width: "80%"}}>
                    {weddingOptions.map((choice, index) => (
                        <div style={{display: "flex", gap: "16px", alignItems: "center"  }}>
                            <input 
                                type="radio"
                                name={name.concat("wedding")}
                                value={choice.value}
                                id={name.concat(choice.value)}
                                key={name.concat(index)}
                                checked={wedding === choice.value}
                                onChange={(e) => handleWeddingInput(e)}
                            />
                            <OptionLabel for={name.concat(choice.value)}>{choice.option}</OptionLabel>
                        </div>
                    ))}
                    </div>
                </div>
                <br/>
                {wedding !== "no" && 
                <>
                    <div>
                        <OptionText style={{marginBottom: "8px"}}>Dinner:</OptionText>
                        <div style={{display: "inline-block", gap: "10px", width: "80%"}}>
                        {dinnerOptions.map((choice, index) => (
                            <div style={{display: "flex", alignItems: "center", paddingBottom: "8px"}}>
                                <input 
                                    type="radio" 
                                    name={name.concat("dinner")} 
                                    value={choice.value} 
                                    id={name.concat(choice.value)}
                                    key={name.concat(index)} 
                                    checked={dinner === choice.value} 
                                    onChange={(e) => handleDinnerInput(e)}
                                />
                                <OptionLabel for={name.concat(choice.value)}>{choice.option}</OptionLabel>
                            </div>
                        ))}
                        </div>
                    </div>
                
                    <br/>
                    <div style={{display: "flex", gap: "16px", width: "80%"}}>
                        <input type="checkbox" id={name.concat("diet")} onChange={(e) => setDietRestriction(e.target.checked)}/>
                        <OptionLabel for={name.concat("diet")}>Dietary Restrictions?</OptionLabel>
                    </div>
                    <br/>
                    {dietRestriction ?
                        <div style={{display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px"}}>
                            <OptionText>Specify:</OptionText>
                            <DietaryRestrictionsInput id={name.concat("restrictions")} onChange={(e) => handleDietRestrictionsInput(e)}/>
                        </div>
                        :
                        <></>
                    }
                </>
            }
            </div>
            <br/>
            <hr style={{width: "100%"}}/>
            <br/>
        </>
    )
}


export default function RSVP() {

    const [ guests, setGuests ] = useState([]);
    const [ selectedGroup, setSelectedGroup] = useState([]);
    const [ guestExists, setGuestExists ] = useState(false);
    const [ guestSelections, setGuestSelections ] = useState({});

    const [ searched, setSearched ] = useState(false);
    const [ failedSubmit, setFailedSubmit ] = useState(false);

    const { width, height } = useWindowSize();
    const narrow = width < 1440;
    const short = height < 800;

    const supportContext = useContext(SupportContextData)

    const checkGuestExists = async (name) => {
        try {
            const resp = await axios.get(
                "/api/guests/exists", 
                { 
                    params: {
                        name: name
                    }
                }
            )
            setGuestExists(resp.data['exists'] === "true" ? true : false)
        } catch(e) {
            if (e.response) {
                console.log(e.response.data)
                console.log(e.response.status)
                console.log(e.response.headers)
            } else if (e.request) {
                console.log(e.request)
            } else {
                console.log('Error', e.message)
            }
        }
    }

    const handleSetSelectedGroup = (guestGroup) => {
        setSelectedGroup(guestGroup);
        checkGuestExists(guestGroup[0])
    }

    const goBack = () => {
        setSelectedGroup([]);
        setGuests([]);
        setGuestSelections({});
        setSearched(false);
        setFailedSubmit(false);
    }

    const handleSetGuestSelectionsCallback = (newState) => {
        setGuestSelections(newState);
        console.log(guestSelections);
    }

    const submitSelections = async () => {
        if (submittable()) {
            try {
                const resp = await axios.post("/api/guests/rsvp", {
                    'selections': guestSelections 
                }).then(
                    setGuestSelections({})
                );
                console.log(resp.data['message']);
                if (resp.data['message'] === 'RSVP Received') {
                    setGuestExists(true);
                    setFailedSubmit(false);
                }
            } catch(e) {
                if (e.response.status === 500) {
                    console.log("could not process selections");
                }
            };
        } else {
            setFailedSubmit(true);
        }
    };

    const submittable = () => {
        for (var i = 0; i < selectedGroup.length; i++) {
            let name = selectedGroup[i]
            if(!(name in guestSelections)) {
                return false;
            } else {
                if(!("wedding" in guestSelections[name])){
                    return false
                } else {
                    if(guestSelections[name]["wedding"] === "yes") {
                        if (!("dinner" in guestSelections[name])) {
                            return false;
                        }
                    }
                }
            }
        };

        return true;
    }

    return (
        <DFC>
        { (selectedGroup.length === 0 || selectedGroup === undefined) ?
        
        <RSVPContainer narrow={narrow} short={short}>
            <GuestSearch guestListCallback={setGuests} searchedCallback={setSearched}/>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px", width: "90%", marginTop: "20px"}}>
                <br/>
                {
                    Object.keys(guests).map((group) => {
                        return(<GuestGroupCard guestGroup={guests[group]} selectedGroupCallback={handleSetSelectedGroup}/>)
                    })
                }
                <br/>
                {searched && <div style={{textAlign: "center", fontSize: "12px", fontWeight: 500}}>If you don't see your name please contact Mike and Maggie or return the RSVP card in the mail</div>}
            </div>
        </RSVPContainer>
        :
        <GroupOptionsContainer narrow={narrow} short={short}>
            {/*<button onClick={() => goBack()}>Back</button>*/}
            <div style={{display: "flex", width: "90%", justifyContent: "start"}}>
                <StyledText style={{cursor: "pointer"}} onClick={() => goBack()}>&lt;&lt; &#20; Back</StyledText>
            </div>
            <br/>
            {guestExists ?
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start", gap: "18px"}}>
                    <StyledText>We have received your RSVP! Thank you!</StyledText>
                    <div style={{fontSize: "12px", fontWeight: 500}}>If you need to make changes to your RSVP, please contact Maggie and Mike directly</div>
                </div>
            :
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <br/>
                    <hr style={{width: "100%"}}/>
                    <br/>
                    {
                        selectedGroup.map((name) =>
                            <GuestResponse name={name} guestSelections={guestSelections} setGuestSelectionsCallback={handleSetGuestSelectionsCallback}/>
                        )
                    }
                    <div style={{display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", justifyContent: "center"}}>
                        <GuestSearchBtn onClick={() => {submitSelections()}}>Submit</GuestSearchBtn>
                        {failedSubmit && 
                            <div style={{fontSize: "14px", fontWeight: 500}}>
                                Please select <i style={{color: "red"}}>wedding</i> and <i style={{color: "red"}}>dinner</i> options for each guest
                            </div>
                        }
                    </div>
                </div>
        }
        </GroupOptionsContainer>
        }
        </DFC>
    )
}