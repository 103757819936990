import styled from 'styled-components';
import { useContext } from 'react';
import { SupportContextData } from "../components/support_context";


const DFC = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
`



export default function MobileRegistry() {

    const supportContext = useContext(SupportContextData)

    return (
        <DFC>
            <img src={supportContext.registry} style={{maxWidth: "90vw"}}/>
            <a href='https://www.target.com/gift-registry/gift-giver?registryId=36790bb0-35fb-11ee-8eb2-3b66df45fbad&clkid=45d0cfbaN916911eeb97913a0d7449a52&cpng=PTID1&lnm=81938&afid=Myregistry%20LLC&ref=tgt_adv_xasd0002&type=WEDDING' target="_blank">
                <img src={supportContext.target_registry} style={{maxWidth: "90vw"}}/>
            </a>
            <a href='https://www.amazon.com/wedding/registry/3OPS6XZZGBVI6?tag=myregistrysync-20' target="_blank">
                <img src={supportContext.amazon_registry} style={{maxWidth: "90vw"}}/>
            </a>
        </DFC>
    )
}