import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useLayoutEffect } from "react";
import Mobile from "./pages/Mobile";
import MobileContainer from "./components/mobile_container";
import RSVP from "./pages/rsvp";
import MobileItinerary from "./pages/mobile_itinerary";
import MobileQA from "./pages/mobile_qa";
import MobileTravel from "./pages/mobile_travel";
import MobileRegistry from "./pages/mobile_registry";





export default function MobilePaths() {


    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<MobileContainer component={Mobile} />} />
                <Route exact path="/rsvp" element={<MobileContainer component={RSVP} />} />
                <Route exact path="/registry" element={<MobileContainer component={MobileRegistry} /> } />
                <Route exact path="/travel" element={<MobileContainer component={MobileTravel} />} />
                <Route exact path="/schedule" element={<MobileContainer component={MobileItinerary} /> } />
                <Route exact path="/qa" element={<MobileContainer component={MobileQA} /> } />
            </Routes>
        </Router>
    )
}