import { useContext } from 'react';
import { SupportContextData } from '../components/support_context';
import useWindowSize from '../components/hooks/useWindowSize';
import { PageImageContainer, PageImage } from '../components/containers';


export default function WeddingParty() {

    const { width, height } = useWindowSize();
    const narrow = width < 1440;
    const short = height < 800;

    const supportContext = useContext(SupportContextData)

    if (!supportContext) {
        return (
            <></>
        )
    } else {
        return (
            <PageImageContainer narrow={narrow} short={short}>
                <PageImage src={supportContext.bridal_party} />
            </PageImageContainer>
        )
    }
}