import { useState, useEffect, createContext } from 'react';
import axios from 'axios';

export const SupportContextData = createContext(null);

export const SupportContext = (props) => {
    const [ supportUrls, setSupportUrls ] = useState(null);

    let userAgentString = navigator.userAgent
    let chromeAgent = userAgentString.indexOf("Chrome") > -1; 
    let safariAgent = userAgentString.indexOf("Safari") > -1; 
    if ((chromeAgent) && (safariAgent)) safariAgent = false; 

    useEffect( () => {

        axios.get('/api/photos/support', {params: {
            safari: (safariAgent ? "true" : "false")
        }}).then((response) => {
            setSupportUrls(response.data.photos);
            console.log(supportUrls);
        }).catch((error) => {
            if (error.response.status === 500) {
                console.log("Error retrieving signed support photo urls");
            }
        });

    }, [])


    return (
        <SupportContextData.Provider value={supportUrls}>
            {props.children}
        </SupportContextData.Provider>
    )
};