import styled from 'styled-components'


export const PageImageContainer = styled.div`
    max-height: ${props => props.short ? "58svh" : (props.narrow ? "64svh" : "68svh")};
    overflow-y: auto;
    margin-top: 2vh;
    text-align: center;
`

export const PageImage = styled.img`
    max-width: 100%;
    height: auto;
`

