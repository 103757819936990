import styled from 'styled-components';
import { useContext } from 'react';
import { SupportContextData } from "../components/support_context";
import { PageImageContainer, PageImage } from '../components/containers';
import useWindowSize from '../components/hooks/useWindowSize';

const HomeContainer = styled.div`
    max-height: 80%;
    overflow-y: auto;
    margin-top: 20px;
    text-align: center;
`

const HomeImage = styled.img`
    max-width: 70%;
`


export default function Home() {

    const { width, height } = useWindowSize();
    const narrow = width < 1440;
    const short = height < 800;

    const supportContext = useContext(SupportContextData)

    if (!supportContext) {
        return (
            <></>
        )
    } else {
        return (
            <PageImageContainer narrow={narrow} short={short}>
                <HomeImage src={supportContext.homepage}/>
                <HomeImage src={supportContext.details}/>
            </PageImageContainer>
        )
    }
}