
import { useState, useEffect, createContext } from 'react';
import axios from 'axios';

export const PhotoContextData = createContext(null);

export const PhotoContext = (props) => {
    const [ photoUrls, setPhotoUrls ] = useState(null);
    const [ ropeUrls, setRopeUrls ] = useState(null);

    let userAgentString = navigator.userAgent
    let chromeAgent = userAgentString.indexOf("Chrome") > -1; 
    let safariAgent = userAgentString.indexOf("Safari") > -1; 
    if ((chromeAgent) && (safariAgent)) safariAgent = false; 

    useEffect( () => {

        axios.get('/api/photos/ropes', {params: {
            safari: (safariAgent ? "true" : "false")
        }}).then((response) => {
            setRopeUrls(response.data.photos);
            console.log(response.data.photos);
        }).catch((error) => {
            if (error.response.status === 500) {
                console.log("Error retrieving signed photo urls");
            }
        });

        axios.get('/api/photos/dump', {params: {
            sign: 'true'
        }}).then((response) => {
            setPhotoUrls(response.data.photos);
            console.log(response.data.photos);
        }).catch((error) => {
            if (error.response.status === 500) {
                console.log("Error retrieving signed photo urls");
            }
        });

    }, []);

    return (
        <PhotoContextData.Provider value={{photoUrls: photoUrls, ropeUrls: ropeUrls}}>
            {props.children}
        </PhotoContextData.Provider>
    )
};