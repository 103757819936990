import styled from 'styled-components';
import useWindowSize from '../components/hooks/useWindowSize';
import { PageImageContainer, PageImage } from '../components/containers';
import { SupportContextData } from '../components/support_context';
import { useContext } from 'react';


const DFC = styled.div`
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
`
const ItineraryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    background-color: white;
    border-radius: 20px;
    border: 1px solid black;
    padding: 12px;
    max-height: ${props => (props.narrow ?  "" : (props.nottooshort ? "58vh" : (props.short ? "30vh" : "68vh")))};
    overflow-y: auto;
`

const StyledLabel = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 2.2em;
    font-weight: 500;
    text-decoration-thickness: 2px;
    color: black;
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 2px;
`

const StyledText = styled.div`
    font-family: 'Just Another Hand', cursive;
    font-size: 2em;
    font-weight: 400;
    color: black;
    text-align: center;
`

const OptionText = styled.div`
    font-size: 14px;
    font-weight: 500;
`

const OptionLabel = styled.div`
    font-size: 18px;
    font-weight: 500;
`


export default function MobileItinerary() {

    const { width, height } = useWindowSize();
    const narrow = width < 1280;
    const short = height < 700;
    const nottooshort = (height >= 700 && height < 950);

    const supportContext = useContext(SupportContextData)

    if(!supportContext) {
        <></>
    } else {
        return (
        <>
        <img src={supportContext.details} style={{maxWidth: "90vw"}} />
        {/*<DFC>
            <ItineraryContainer narrow={narrow} short={short} nottooshort={nottooshort}>
                <div style={{textAlign: "center"}}>
                    <StyledLabel>Saturday, August 31, 2024</StyledLabel>
                    <p><StyledText>Welcome Party</StyledText></p>
                    <p>
                        <OptionLabel>Recovery Sport Grill</OptionLabel>
                        <OptionLabel>5270 Willow Place, Verona, NY 13478</OptionLabel>
                        <OptionText>(shares a parking lot with the Fairfield Inn)</OptionText>
                    </p>
                    <p><OptionText>Casual Attire</OptionText></p>
                    <p><OptionText>Everyone is invited to join us Saturday evening for a welcome party! Appetizers and drinks will be provided.</OptionText></p>
                </div>
                <div style={{textAlign: "center"}}>
                <StyledLabel>Sunday, September 1, 2024</StyledLabel>
                    <p><StyledText>Wedding Day</StyledText></p>
                    <p>
                        <OptionLabel>Hayloft on the Arch</OptionLabel>
                        <OptionLabel>5742 Norton Rd</OptionLabel>
                        <OptionLabel>Vernon Center NY, 13477</OptionLabel>
                    </p>
                    <p>
                        <OptionText>Shuttle service will be provided from Fairfield Inn</OptionText>
                        <OptionText>Formal Cocktail Attire</OptionText>
                    </p>
                </div>
                <hr style={{width: "90%"}}/>
                <div style={{textAlign: "left", width: "90%"}}>
                    <OptionLabel>3:30 pm - 4:00 pm</OptionLabel>
                </div>
                <div style={{textAlign: "center"}}>
                    <p><StyledText>Ceremony</StyledText></p>
                </div>
                <hr style={{width: "90%"}}/>
                <div style={{textAlign: "left", width: "90%"}}>
                    <OptionLabel>4:00 pm - 5:00 pm</OptionLabel>
                </div>
                <div sytle={{textAlign: "center"}}>
                    <p><StyledText>Cocktail Hour</StyledText></p>
                </div>
                <hr style={{width: "90%"}}/>
                <div style={{textAlign: "left", width: "90%"}}>
                    <OptionLabel>5:00 pm - 10:00 pm</OptionLabel>
                </div>
                <div sytle={{textAlign: "center"}}>
                    <p><StyledText>Reception</StyledText></p>
                </div>
                <hr style={{width: "90%"}}/>
                <div style={{textAlign: "center"}}>
                    <p>
                        <StyledLabel>After Party</StyledLabel>
                    </p>
                    <p>
                        <OptionLabel>Turning Stone Resort & Casino</OptionLabel>
                    </p>
                    <p>
                        <OptionText>Stop in for an after-hour cocktail with the newlyweds!</OptionText>
                        <br />
                        <OptionText>Fairfield Inn provides a complimentary shuttle to and back from the Turning Stone Resort and Casino until 2:00 am</OptionText>
                    </p>
                </div>
                <br />
                <br />
                <div style={{textAlign: "left", width: "90%"}}>
                    <OptionLabel>10:00 pm - 11:00 pm</OptionLabel>
                </div>
                <div sytle={{textAlign: "center"}}>
                    <p><StyledText>NY Rec & Social Club</StyledText></p>
                </div>
                <div style={{textAlign: "left", width: "90%"}}>
                    <OptionLabel>11:00 pm - ?</OptionLabel>
                </div>
                <div style={{textAlign: "center"}}>
                    <p><StyledText>Motif Bar & Lounge</StyledText></p>
                </div>
            </ItineraryContainer>
        </DFC>*/}
        </>
        )
    }
}
