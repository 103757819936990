import styled from 'styled-components';
import { useEffect, useState, useContext } from 'react';
import { SupportContextData } from './support_context';
import ClipLoader from "react-spinners/ClipLoader";

const BackgroundRight = styled.div`
    position: absolute;
    display: flex;
    justify-content: left;
    right: -15%;
    transform: ${props => props.animate ? "translate(46vw) scale(1.4)" : ""};
    width: 50vw;
    height: 100vh;
    transition: 3.2s;
    animation-timing-function: ease-in;
    z-index: 99;
`

const BackgroundLeft = styled.div`
    position: absolute;
    display: flex;
    justify-content: right;
    left: -15%;
    transform: ${props => props.animate ? "translate(-46vw) scale(1.4)" : ""};
    width: 50vw;
    height: 100vh;
    transition: 3.2s;
    animation-timing-function: ease-in;
    z-index: 99;
`

const BackgroundTop = styled.div`
    position: absolute;
    display: flex;
    align-content: bottom;
    justify-content: center;
    transform: ${props => props.animate ? "translateY(-12vh) scale(1.4)" : ""};
    width: 100vw;
    height: 10vh;
    transition: 3.2s;
    animation-timing-function: ease-in;
    z-index: 99;
`

const RightDoor = styled.div`
    position: absolute;
    display: flex;
    justify-content: left;
    right: 0%;
    transform: ${props => props.animate ? "translate(66vw) scale(1.6)" : ""};
    width: 50vw;
    height: 100vh;
    transition: 3.2s;
    animation-timing-function: ease-in;
    z-index: 99;
`;


const LeftDoor = styled.div`
    position: absolute;
    display: flex;
    justify-content: right;
    left: 0%;
    transform: ${props => props.animate ? "translate(-66vw) scale(1.6)" : ""};
    width: 50vw;
    height: 100vh;
    transition: 3.2s;
    animation-timing-function: ease-in;
    z-index: 99;
`;

const DoorTrack = styled.div`
    position: absolute;
    display: flex;
    align-content: bottom;
    justify-content: center;
    transform: ${props => props.animate ? "translateY(-10vh) scale(1.6)" : ""};
    width: 100vw;
    height: 4vh;
    transition: 3.2s;
    animation-timing-function: ease-in;
    z-index: 99;
`;


export default function Doors({doorsCallback}) {

    const supportContext = useContext(SupportContextData)

    const [ animate, setAnimate ] = useState(false);

    const [ backgroundRightLoaded, setBackgroundRightLoaded ] = useState(false);
    const [ backgroundLeftLoaded, setBackgroundLeftLoaded ] = useState(false);
    const [ backgroundTopLoaded, setBackgroundTopLoaded ] = useState(false);
    const [ doorTrackLoaded, setDoorTrackLoaded ] = useState(false);
    const [ leftDoorLoaded, setLeftDoorLoaded ] = useState(false);
    const [ rightDoorloaded, setRightDoorLoaded ] = useState(false);


    useEffect(() => {
        if (backgroundRightLoaded && backgroundLeftLoaded && backgroundTopLoaded && doorTrackLoaded && leftDoorLoaded && rightDoorloaded) {
            setTimeout(() => setAnimate(true), 300);
            setTimeout(() => doorsCallback(false), 3500);
        }
    }, [backgroundRightLoaded, backgroundLeftLoaded, backgroundTopLoaded, doorTrackLoaded, leftDoorLoaded, rightDoorloaded])
    
    if (!supportContext) {
        return (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", background: "black", height: "100%", width: "100%"}}>
                <ClipLoader
                    color={"white"}
                    loading={true}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        )
    } else {
        return (
            <>
            <div> 
                <BackgroundRight animate={animate}>
                    <img src={supportContext.background_right} width="100%" onLoad={() => setBackgroundRightLoaded(true)}/>
                </BackgroundRight>
                <BackgroundLeft animate={animate}>
                    <img src={supportContext.background_left} width="100%" onLoad={() => setBackgroundLeftLoaded(true)}/>
                </BackgroundLeft>
                <BackgroundTop animate={animate}>
                    <img src={supportContext.background_top} width="100%" onLoad={() => setBackgroundTopLoaded(true)}/>
                </BackgroundTop>
                <DoorTrack animate={animate}>
                    <img src={supportContext.door_track} onLoad={() => setDoorTrackLoaded(true)}/>
                </DoorTrack>
                <LeftDoor animate={animate}>
                    <img src={supportContext.left_door} onLoad={() => setLeftDoorLoaded(true)}/>
                </LeftDoor>
                <RightDoor animate={animate}>
                    <img src={supportContext.right_door} onLoad={() => setRightDoorLoaded(true)}/>
                </RightDoor>
            </div>
            {(!backgroundRightLoaded || !backgroundLeftLoaded || !backgroundTopLoaded || !doorTrackLoaded || !leftDoorLoaded || !rightDoorloaded) && 
                <div style={{display: "flex", alignItems: "cente", justifyContent: "center", background: "black", height: "100%", width: "100%"}}></div>
            }
            </>
        )
    }
}